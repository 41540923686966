<template>
    <div>
        <h3 class="tab-header">Cadeaubon</h3>

        <g-form
            :action="`/api/admin/company/${this.$route.params.company_id}/giftCard`"
            :prepopulate="true"
            :prepopulate-with-post-response="true"
            @prepopulated="prepopulated"
            :before="beforeSubmit"
            :form="this.settingsForm"
            message="Instellingen opgeslagen"
        >
            <div v-if="templatePreview" class="columns column-form is-desktop">
                <div class="column is-offset-3-desktop is-offset-2-widescreen">
                    <img :src="this.templatePreview"/><br/>

                    <p><a :href="this.settingsForm.template_url" class="external">Template downloaden</a></p>
                    <p><a :href="this.settingsForm.print_file_url" target="_blank" class="external">Werkbestand (demo)
                        downloaden</a></p>
                </div>
            </div>

            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Drukwerk</div>
                </div>

                <div class="column is-4-widescreen">
                    <p><small>Het drukwerkbestand wordt overschreven en de ontwerp-tool wordt
                        uitgeschakeld voor het bedrijf.</small></p>

                    <g-file name="print_file" label="PDF-bestand" accept="application/pdf"/>

                    <g-switch name="prevent_preview_updates" :label="$t('admin.company.prevent_preview_updates')"/>
                    <g-switch name="block_design_editor" :label="$t('admin.company.block_design_editor')"/>
                </div>
            </div>

            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Lokalisatie</div>
                </div>

                <div class="column is-4-widescreen">
                    <g-select
                        name="locale"
                        :options="locales"
                        label="Standaard taal cadeaubon"
                    />

                    <g-input name="title" label="Titel" placeholder="Cadeaubon" />

                    <g-input name="value_indicator" label="Waardeomschrijving" placeholder="Ter waarde van" />
                </div>
            </div>

            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Geldigheid</div>
                </div>

                <div class="column is-4-widescreen">
                    <g-select
                        label="Geldigheid cadeaubonnen"
                        name="gift_card_expiration"
                        :options="giftCardExpirationOptions"
                        valuekey="id"
                    />

                    <g-select
                        label="Verlopen cadeaubon verlengen met"
                        name="gift_card_extend_duration"
                        :options="giftCardExtendOptions"
                        valuekey="id"
                    />

                    <g-switch
                        name="gift_card_expiration_strict"
                        label="Vervaldatum strikt hanteren"
                        subtitle="Door deze optie uit te schakelen heeft het bedrijf de mogelijkheid een verlopen cadeaubon te verlengen."
                    />
                </div>
            </div>

            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Restricties</div>
                </div>

                <div class="column is-4-widescreen">
                    <div class="field-header">Verzilver limiet online</div>
                    <p><small>Limiet die wordt toegepast op online verzilveringen. Leeg laten betekent geen
                        limiet.</small></p>
                    <g-format ref="online_redeem_limit"
                              label="Bedrag"
                              name="online_redeem_limit"
                              type="currency"/>

                    <div class="field-header">Toegestane uitgifte typen</div>
                    <g-select name="allowed_issue_type" label="Opties" :options="issueTypeOptions"></g-select>

                    <div class="field-header">Opwaardering toestaan</div>
                    <g-select name="allow_card_top_up" label="Opties" options="trueFalse" />
                </div>
            </div>



            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Instellingen</div>
                </div>

                <div class="column is-4-widescreen">
                    <div class="field-header">Cadeaubon branding</div>
                    <g-select :options="brandingOptions"
                              label="Opties"
                              name="branding_id"
                              valuekey="id"/>

                    <div class="field-header">Voorbeeldbedrag</div>
                    <g-format ref="value_label"
                              label="Bedrag (of leeg laten)"
                              name="value_label"
                              type="currency"/>

                    <g-switch
                        name="premium_fulfilment"
                        label="Premium fulfilment"
                        subtitle="Drukwerk wordt gemarkeerd aangeleverd bij de drukker"
                    />

                </div>
            </div>

            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Wallet</div>
                </div>
                <div class="column is-4-widescreen">
                    <div class="columns">
                        <div class="column">
                            <div class="field-header">Achtergrondkleur</div>
                            <div class="field-body">
                                <g-colorpicker name="wallet_background_color"/>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field-header">Tekstkleur</div>
                            <div class="field-body">
                                <g-colorpicker name="wallet_foreground_color"/>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field-header">Labelkleur</div>
                            <div class="field-body">
                                <g-colorpicker name="wallet_label_color"/>
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <div class="field-header">Achtergrondkleur (button)</div>
                            <div class="field-body">
                                <g-colorpicker name="wallet_button_background_color"/>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field-header">Tekstkleur (button)</div>
                            <div class="field-body">
                                <g-colorpicker name="wallet_button_foreground_color"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">QR-Code</div>
                </div>

                <div class="column is-4-widescreen">
                    <div class="field-header">Zichtbaarheid</div>
                    <g-select :options="qrCodeVisibilityOptions"
                              label="Opties"
                              name="qr_code_visibility"/>

                    <div class="field-header">
                        Variant

                        <small class="pl-2" v-if="settingsForm.qr_code_variant === 'wallet'">wallet.gifty.nl/JW96S75S9FV8L9S4</small>
                        <small class="pl-2" v-if="settingsForm.qr_code_variant === 'code'">JW96S75S9FV8L9S4</small>
                    </div>
                    <g-select :options="qrCodeVariantOptions"
                              name="qr_code_variant"/>

                    <br/>
                    <g-button type="submit">Opslaan</g-button>
                </div>
            </div>
        </g-form>
    </div>
</template>

<script>
import {formatCurrencyInput} from "../../../utils/Consts";
import {mapGetters} from "vuex";

export default {
    name: "Pricing",
    data() {
        return {
            brandingOptions: [],
            qrCodeVariantOptions: [
                {
                    name: "Wallet URL",
                    value: "wallet",
                },
                {
                    name: "Enkel de code",
                    value: "code",
                }
            ],
            issueTypeOptions: [
                {
                    name: "Arrangementen en geldwaarde",
                    value: "both",
                },
                {
                    name: "Enkel arrangementen",
                    value: "package_only",
                },
                {
                    name: "Enkel geldwaarde",
                    value: "monetary_only",
                }
            ],
            qrCodeVisibilityOptions: [
                {
                    name: "Altijd",
                    value: "always",
                },
                {
                    name: "Enkel voor winkelbonnen",
                    value: "store",
                },
                {
                    name: "Enkel voor online bonnen",
                    value: "online",
                },
                {
                    name: "Enkel voor digitale bonnen",
                    value: "digital",
                },
                {
                    name: "Nooit",
                    value: "never",
                }
            ],
            giftCardExpirationOptions: [
                {
                    id: null,
                    name: 'Onbeperkt',
                },
                {
                    id: 30,
                    name: '1 maand',
                },
                {
                    id: 30 * 2,
                    name: '2 maanden',
                },
                {
                    id: 30 * 3,
                    name: '3 maanden',
                },
                {
                    id: 30 * 4,
                    name: '4 maanden',
                },
                {
                    id: 30 * 5,
                    name: '5 maanden',
                },
                {
                    id: 30 * 6,
                    name: '6 maanden',
                },
                {
                    id: 30 * 7,
                    name: '7 maanden',
                },
                {
                    id: 30 * 8,
                    name: '8 maanden',
                },
                {
                    id: 30 * 9,
                    name: '9 maanden',
                },
                {
                    id: 30 * 10,
                    name: '10 maanden',
                },
                {
                    id: 30 * 11,
                    name: '11 maanden',
                },
                {
                    id: 365,
                    name: '1 jaar',
                },
                {
                    id: 730,
                    name: '2 jaar',
                },
                {
                    id: 1095,
                    name: '3 jaar',
                },
                {
                    id: 1460,
                    name: '4 jaar',
                },
                {
                    id: 1825,
                    name: '5 jaar',
                },
                {
                    id: 2190,
                    name: '6 jaar',
                },
                {
                    id: 2555,
                    name: '7 jaar',
                },
                {
                    id: 2920,
                    name: '8 jaar',
                },
                {
                    id: 3285,
                    name: '9 jaar',
                },
                {
                    id: 3650,
                    name: '10 jaar',
                },
            ],
            giftCardExtendOptions: [
                {
                    id: 30,
                    name: '1 maand',
                },
                {
                    id: 30 * 2,
                    name: '2 maanden',
                },
                {
                    id: 30 * 3,
                    name: '3 maanden',
                },
                {
                    id: 30 * 4,
                    name: '4 maanden',
                },
                {
                    id: 30 * 5,
                    name: '5 maanden',
                },
                {
                    id: 30 * 6,
                    name: '6 maanden',
                },
                {
                    id: 30 * 7,
                    name: '7 maanden',
                },
                {
                    id: 30 * 8,
                    name: '8 maanden',
                },
                {
                    id: 30 * 9,
                    name: '9 maanden',
                },
                {
                    id: 30 * 10,
                    name: '10 maanden',
                },
                {
                    id: 30 * 11,
                    name: '11 maanden',
                },
                {
                    id: 365,
                    name: '1 jaar',
                },
                {
                    id: 730,
                    name: '2 jaar',
                },
                {
                    id: 1095,
                    name: '3 jaar',
                },
                {
                    id: 1460,
                    name: '4 jaar',
                },
                {
                    id: 1825,
                    name: '5 jaar',
                },
                {
                    id: 2190,
                    name: '6 jaar',
                },
                {
                    id: 2555,
                    name: '7 jaar',
                },
                {
                    id: 2920,
                    name: '8 jaar',
                },
                {
                    id: 3285,
                    name: '9 jaar',
                },
                {
                    id: 3650,
                    name: '10 jaar',
                },
            ],
            templatePreview: null,
            settingsForm: new Form({
                locale: null,
                title: null,
                value_indicator: null,
                value_label: null,
                branding_id: null,
                print_file: null,
                block_design_editor: null,
                template_url: null,
                print_file_url: null,
                online_redeem_limit: null,
                gift_card_expiration: null,
                gift_card_expiration_strict: false,
                gift_card_extend_duration: null,
                allowed_issue_type: null,
                allow_card_top_up: false,
                premium_fulfilment: null,
                qr_code_visibility: null,
                qr_code_variant: null,
                wallet_background_color: null,
                wallet_foreground_color: null,
                wallet_label_color: null,
                wallet_button_background_color: null,
                wallet_button_foreground_color: null,
                prevent_preview_updates: null,
            }),
        }
    },
    computed: {
        ...mapGetters({
            locales: 'application/availableLocalesTranslated',
        }),
    },
    methods: {
        prepopulated({data}) {
            this.brandingOptions = data.available_brandings;

            if (data.online_redeem_limit !== null) {
                this.settingsForm.online_redeem_limit = this.$options.filters.currency(data.online_redeem_limit / 100)
            }

            if (data.value_label !== null) {
                this.settingsForm.value_label = this.$options.filters.currency(data.value_label / 100)
            }

            if (data.images && data.images.length > 0) {
                this.templatePreview = data.images[0].url;
            }

            // Set Wallet variables
            this.settingsForm.wallet_background_color        = data.wallet.design.background_color
            this.settingsForm.wallet_foreground_color        = data.wallet.design.foreground_color
            this.settingsForm.wallet_label_color             = data.wallet.design.label_color
            this.settingsForm.wallet_button_background_color = data.wallet.design.button_background_color
            this.settingsForm.wallet_button_foreground_color = data.wallet.design.button_foreground_color
        },
        beforeSubmit(form) {
            if (form.online_redeem_limit === '') {
                form.online_redeem_limit = null
            }

            if (form.value_label === '') {
                form.value_label = null
            }

            if (form.online_redeem_limit !== null) {
                form.online_redeem_limit = formatCurrencyInput(this.$refs.online_redeem_limit.getRawValue())
            }

            if (form.value_label !== null) {
                form.value_label = formatCurrencyInput(this.$refs.value_label.getRawValue())
            }

            return form
        }
    },
}
</script>

<style scoped>

</style>
