<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <g-form
                    class="popup-dialog"
                    :form="form"
                    :action="`/api/admin/order/${this.id}/refund`"
                    :prepopulate="true"
                    :loading="isLoading"
                    @loading-state="(state) => isLoading = state"
                    @success="this.destroy"
                >
                    <div class="popup-title">
                        <h1 class="title is-1">Terugbetaling</h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <div class="popup-body">
                        <div class="notification is-danger" v-if="form.available_balance < form.amount">
                            <small>De balans van het bedrijf is onvoldoende om deze terugbetaling te dekken, dit zal
                                resulteren in een negatieve balans voor het bedrijf.</small>
                        </div>

                        <div class="notification" v-if="form.coupon_transaction">
                            <small>Deze betaling is (deels) voldaan met een kortingscode. Deze wordt weer geactiveerd na
                                de terugbetaling.</small>
                        </div>

                        <div class="columns">
                            <div class="column is-one-quarter"><strong>E-mail</strong></div>
                            <div class="column">{{ form.email }}</div>
                        </div>
                        <div class="columns">
                            <div class="column is-one-quarter"><strong>Bedrag</strong></div>
                            <div class="column">{{ form.amount / 100 | currency }}</div>
                        </div>
                        <div class="columns">
                            <div class="column is-one-quarter"><strong>Balans</strong></div>
                            <div class="column">{{ form.available_balance / 100 | currency }}</div>
                        </div>
                        <div class="columns">
                            <div class="column is-one-quarter"><strong>Kosten</strong></div>
                            <div class="column">
                                <g-switch
                                    name="compensate_company_fees"
                                    label="Bedrijf compenseren"
                                    subtitle="De kosten die ingehouden zijn bij het bedrijf terugbetalen. Dit wordt op de eerstvolgende uitbetalings-factuur vermeld."
                                />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-one-quarter"><strong>Notificaties</strong></div>
                            <div class="column">
                                <g-switch
                                    name="notify_company"
                                    label="E-mail naar bedrijf"
                                    subtitle="Het bedrijf ontvangt een e-mail waarin vermeld wordt dat we een terugbetaling afgehandeld hebben."
                                />
                                <g-switch
                                    name="notify_consumer"
                                    label="E-mail naar consument"
                                    subtitle="De consument ontvangt een e-mail over de terugbetaling en indien van toepassing de geactiveerde kortingscode."
                                />
                            </div>
                        </div>
                    </div>

                    <div class="popup-footer">
                        <a @click.prevent="destroy" href="#" role="button">Annuleren</a>
                        <g-button>Terugbetalen</g-button>
                    </div>
                </g-form>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "OrderRefundForm",
    data() {
        return {
            isLoading: false,
            form: new Form({
                email: null,
                amount: null,
                available_balance: null,
                compensate_company_fees: null,
                coupon_transaction: null,
                notify_company: null,
                notify_consumer: null,
            })
        }
    },
    props: {
        id: {
            type: Number,
        },
    },
}
</script>

<style scoped>

</style>
