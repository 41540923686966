// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../images/layout/blueprint-bg.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tab-content[data-v-40ac76b6]{display:flex;flex:1;flex-direction:column;justify-content:space-between}.blueprint-bg[data-v-40ac76b6]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") repeat #f6f9fb;background-blend-mode:darken;background-position-x:-5px}", "",{"version":3,"sources":["webpack://./resources/assets/js/components/Popup/TabbedViewItem.vue"],"names":[],"mappings":"AACA,8BAEI,YAAA,CADA,MAAA,CAEA,qBAAA,CACA,6BAAJ,CAGA,+BACI,iEAAA,CACA,4BAAA,CAGA,0BAFJ","sourcesContent":["\n.tab-content {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.blueprint-bg {\n    background: url('../../../images/layout/blueprint-bg.svg') repeat #F6F9FB;\n    background-blend-mode: darken;\n\n    // The background starts with a border on the left side. This can look funny if the container itself also has a border\n    background-position-x: -5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
